<template>
  <v-layout>
    <v-app-bar color="white" dark fixed app>
      <Logo/>

      <v-toolbar-items>
        <v-btn exact v-for="item in menu" :key="item.icon" :to="item.link" text color="blue darken-1">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer />

      <User/>
    </v-app-bar>

    <v-container fluid>
      <router-view />
    </v-container>
  </v-layout>
</template>

<script>
import Logo from '@/components/AppBar/Logo'
import User from '@/components/AppBar/User'

export default {
  components: {
    Logo,
    User
  },

  data () {
    return {
      menu: [
        {
          title: 'Главная',
          link: '/director'
        },
        {
          title: 'Менеджеры',
          link: '/director/managers'
        },
        {
          title: 'Начальники филиалов',
          link: '/director/filial_managers'
        },
        {
          title: 'МРГ',
          link: '/director/companies'
        },
        {
          title: 'ГРО',
          link: '/director/coordinators'
        },
        {
          title: 'Цены',
          link: '/director/prices'
        },
        {
          title: 'Касса',
          link: '/director/incomes'
        },
        {
          title: 'Поверки',
          link: '/director/verifications'
        },
        {
          title: 'Счётчики',
          link: '/director/meters'
        },
        {
          title: 'Марки',
          link: '/director/meter_brands'
        }
      ]
    }
  },

  beforeMount() {
    if (this.$store.getters.user.role !== 'director') {
      this.$router.push({path: `/${this.$store.getters.user.role}`})
    }
  }
}
</script>
